import type { GroupsCategory } from '@orus.eu/activity'
import type { Amount } from '@orus.eu/amount'
import type { OrusSessionPermissions } from '@orus.eu/backend/src/services/permissions'
import type { CompanyIdNumber } from '@orus.eu/company-id-number'
import type { EmbeddingPartner } from '@orus.eu/dimensions'
import { isDiscountCode } from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import { useAsyncCallback, useCrash } from '@orus.eu/pharaoh'
import type { JsonableSiretData } from '@orus.eu/siret'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { useState } from 'react'
import { v4 } from 'uuid'
import { trpc } from '../../client'
import { usePermissions } from '../use-permissions'

export type StartSelfOnboardingParams = {
  /**
   * Use this parameter to create a subscription that will be embedded in a partner subscription
   * process (see embedingPartnerDimension)
   */
  embeddingPartner?: EmbeddingPartner
  /**
   * Use this parameter to create a subscription that starts and the second step fo the
   * activity search. Exclusive with `activity`
   */
  category?: GroupsCategory
  /**
   * Use this parameter to create a subscription with an already selected activity. Exclusive with `category`
   */
  activity?: string
  firstName?: string
  lastName?: string
  companyIdNumber?: CompanyIdNumber
  siretData?: JsonableSiretData
  email?: string
  estimatedRevenue?: Amount
  phone?: string
}

export function useStartSelfOnboarding(): (startSelfOnboardingParams?: StartSelfOnboardingParams) => void {
  const searchParams = useSearch({ strict: false })
  const navigate = useNavigate()
  const subscriptionFunnelAvailable = subscriptionFunnelAvailableForByPermissionType[usePermissions().type]

  const [startSelfOnboardingIdempotencyKey] = useState(v4())
  const crash = useCrash()

  const discountCodeInUrl = searchParams.discount_code

  const discountCode = discountCodeInUrl && isDiscountCode(discountCodeInUrl) ? discountCodeInUrl : undefined

  return useAsyncCallback(
    async (startSelfOnboardingParams?: StartSelfOnboardingParams) => {
      if (!subscriptionFunnelAvailable) {
        void navigate({ to: '/' })
        return
      }

      const result = await trpc.selfOnboarding.createSubscriptionAsExternalUser.mutate({
        discountCode,
        ...startSelfOnboardingParams,
        idempotencyKey: startSelfOnboardingIdempotencyKey,
      })

      if (result.type === 'failure') {
        if (result.problem.type === 'invalid-link') {
          crash({ type: 'invalid-link' })
          return
        }

        throw new TechnicalError('Error while creating subscription as external user', {
          context: { err: result.problem },
        })
      }

      void navigate({
        ...(result.output.stepId
          ? {
              to: '/subscribe/$subscriptionId/$stepId',
              params: { subscriptionId: result.output.subscriptionId, stepId: result.output.stepId },
            }
          : { to: '/subscribe-no-possible-step' }),
        search: searchParams,
      })
    },
    [crash, searchParams, discountCode, navigate, startSelfOnboardingIdempotencyKey, subscriptionFunnelAvailable],
  )
}

const subscriptionFunnelAvailableForByPermissionType: Record<OrusSessionPermissions['type'], boolean> = {
  partner: false,
  platform: false,
  client: true,
}
